import Vue from "vue";
import VueRouter from "vue-router";
import MasterPage from "../MasterPage.vue";
import Links from "../views/Links.vue";
import Approbations from "../views/Approbations.vue";
import Approbation from "../views/Approbation.vue";
import Link from "../views/Link.vue";
import Users from "../views/Users.vue";
import User from "../views/User.vue";
import Settings from "../views/Settings.vue";
import Login from "../views/Login.vue";
import NotFound from "../views/NotFound.vue";
import Remote from "../views/Remote.vue";
import SignUp from "../views/SignUp.vue";
import ResetPassword from "../views/ResetPassword.vue";
import FindALink from "../views/FindALink.vue";

import * as firebase from "@/firebase-init.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MasterPage,
    meta: { requiresAuth: true },
    children: [
      { path: "/", redirect: "/findmylink" },
      { path: "/findmylink", name: "findmylink", component: FindALink },
      {
        path: "/links",
        name: "links",
        component: Links,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/approbations",
        name: "approbations",
        component: Approbations,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/approbation/:id",
        name: "approbation",
        component: Approbation,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/link/:id",
        name: "link",
        component: Link,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/users",
        name: "users",
        component: Users,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/user/:id",
        name: "user",
        component: User,
        meta: { requiresTechOrAdmin: true },
      },
      {
        path: "/remote/:id/:email",
        name: "remote",
        component: Remote,
        meta: { requiresTechOrAdmin: true },
      },
      { path: "/settings", name: "settings", component: Settings },
    ],
  },
  {
    path: "/__/auth/action",
    component: ResetPassword,
    meta: { requiresNonAuth: true },
  },
  { path: "/login", component: Login, meta: { requiresNonAuth: true } },
  { path: "/signup", component: SignUp, meta: { requiresNonAuth: true } },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresTechOrAdmin = to.matched.some(
    (record) => record.meta.requiresTechOrAdmin
  );

  let currentUser = await firebase.getCurrentUser();

  if (requiresAuth && !currentUser) {
    next("login");
  }

  if (requiresAdmin && !currentUser.roles.includes("admin")) {
    next(false);
  }

  if (
    requiresTechOrAdmin &&
    !currentUser.roles.includes("admin") &&
    !currentUser.roles.includes("tech")
  ) {
    next(false);
  }

  next(); // You may have an infinite update loop in a component render function.
});

export default router;
